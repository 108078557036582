<template>
  <div>
    <a-select
      v-model:value="currency"
      :filter-option="filterOption"
      :size="size"
      :placeholder="$t('Currency')"
      show-search
      @change="handleChange"
    >
      >
      <a-select-option
        v-for="c in currencies"
        :key="c.currency"
        :value="c.abbreviation"
        :data="c"
      >
        {{ c[labelKey] }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import currencies from './currencies';

export default {
  name: 'InputCurrency',
  props: {
    valueModel: {
      type: String,
      default() {
        return undefined;
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
    labelKey: {
      type: String,
      default() {
        return 'currency';
      },
    },
  },
  emits: ['change', 'update:valueModel'],
  data() {
    return {
      currencies,
      currency: '',
    };
  },
  watch: {
    valueModel: {
      immediate: true,
      handler(nv) {
        this.currency = nv;
      },
    },
  },
  methods: {
    filterOption(input, option) {
      const { currency = '', abbreviation = '' } = option.data || {};

      return currency.toLowerCase().indexOf(input.toLowerCase()) >= 0
        || abbreviation.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleChange() {
      this.$emit('change', this.currency);
      this.$emit('update:valueModel', this.currency.toString());
    },
  },
};
</script>

<style scoped></style>
